@import '../../helpers/styles';

.remayk-gen-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @include mobile {
        align-items: stretch;
    }
}

.remayk-gen-page-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #141414CC;
    border-radius: 1.5rem;
    max-width: min(500pt, 100%);
    z-index: 1;

    @include desktop {
        margin: 1rem 1rem 0.5rem 1rem;
        padding: 1rem;
        width: 500pt;
    }

    @include mobile {
        padding: 1rem;
        margin: 1rem;
    }
}

.remayk-gen-page-title {
    font-family: "Sequel-75";
    font-size: 1rem;
    color: white;
    padding-bottom: 1.5rem;
    padding-top: 0.5rem;
}

.remayk-gen-page-create-button {
    width: 100% !important;
    margin-top: 2rem !important;
}

.remayk-gen-page-audio {
    margin-top: 1rem;
}

.remayk-gen-page-description {
    color: white;
    font-family: "Sequel-75";
    font-size: 0.8rem;
    text-align: center;
}