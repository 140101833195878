.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #FFF;
  background-color: rgba(255, 255, 255, 0.05);
  z-index: 1;
}
@media only screen and (min-width: 601px), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 601px), only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 601px), only screen and (min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-resolution: 192dpi) and (min-width: 601px), only screen and (min-resolution: 2dppx) and (min-width: 601px) {
  .header-container {
    height: 74px;
    min-height: 74px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media only screen and (max-width: 600px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 600px), only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 600px), only screen and (min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-resolution: 192dpi) and (max-width: 600px), only screen and (min-resolution: 2dppx) and (max-width: 600px) {
  .header-container {
    height: 44px;
    min-height: 44px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

.header-home-text {
  color: #DBFF00;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0 0;
  font-family: "Sequel-75";
}
@media only screen and (min-width: 601px), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 601px), only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 601px), only screen and (min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-resolution: 192dpi) and (min-width: 601px), only screen and (min-resolution: 2dppx) and (min-width: 601px) {
  .header-home-text {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 600px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min--moz-device-pixel-ratio: 2) and (max-width: 600px), only screen and (-o-min-device-pixel-ratio: 2/1) and (max-width: 600px), only screen and (min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-resolution: 192dpi) and (max-width: 600px), only screen and (min-resolution: 2dppx) and (max-width: 600px) {
  .header-home-text {
    font-size: 14px !important;
  }
}

.Header-menu {
  border: none;
  cursor: pointer;
}

